<template>
    <div class="statistmain" :style="{zoom:wsw/19.20 + '%'}">
        <div class="stathed">
            <img class="bg" alt="" src="../../img/header.png"/>
            <div class="textbox">
                <p>{{ username }}</p>
                <p>MES系统生产管理看板 </p>
            </div>
            <div class="tabbox">
                <div class="tab " @click="goto('/statistics')">首页</div>
                <div class="tab avtive" @click="goto('/home')">设备看板</div>
                <div class="tab " @click="goto('/orderOverview')">订单看板</div>
                <div class="tab " @click="goto('/personnelOverview')">人员看板</div>
            </div>
            <div class=" sy" @click="offall">
                进入主控台
            </div>
            <div class="sjbox">
                <p>{{ dayjs(date).format('HH:mm') }}</p>
                <p>{{ dayjs(date).format('YYYY-MM-DD') }}</p>
            </div>
        </div>
        <div class="stebox ">
            <div class="steboxhed">
                设备信息
            </div>
            <div class="setmain">
                <div class="settabbox">
                    <div class="settab " :class="xstype == 'all' ? 'active' : ''" @click="tan('all')">全部
                        <div class="xian"></div>

                    </div>
                    <div class="settab " :class="xstype == item.group_name ? 'active' : ''" @click="tan(item.group_name)"
                        v-for="item in list" :key="item.name">{{ item.group_name }} ({{ item.devices.length }})
                        <div class="xian"></div>
                    </div>
                </div>
                <el-carousel class="ddboxsw" height="calc(100% - 28px)" :interval="3000" indicator-position="outside">
                    <el-carousel-item v-for="(item, index) in swlistdata" :key="index">
                        <div class="ddbox">
                            <div class="jq" v-for="item2 in item" :key="item2.nick_name">
                                <div v-if="item2.iot_id != ''">
                                    <img v-if="item2.status == 'standby'" src="../../img/jq2.png" alt="">
                                    <img v-if="item2.status == 'online' " src="../../img/jq1.png" alt="">
                                    <img v-if="item2.status == 'offline' " src="../../img/jq3.png" alt="">
                                 </div>
                          
                                <div class="name">{{ item2.nick_name }}<span v-if="item2.iot_id == ''">(虚拟设备)</span> </div>
                                <div class="datatext">部门：{{ item2.group_name }}</div>
                                <div class="datatext"> 当前工序：{{  item2.tasks.length ? (item2.tasks[0].order_id ? 
                                (item2.tasks[0].order_name + '-' + item2.tasks[0].order_item_name + '-' + item2.tasks[0].order_process_name) 
                               : item2.tasks[0].work_content)  : '暂无任务'  }}</div>
                                <div class="datatext">操作人：{{ item2.tasks.length ? item2.tasks[0].name : '暂无' }}</div>
                                <div class="datatext">分配时间：{{ item2.tasks.length ? item2.tasks[0].created_at : '暂无' }}</div>
                                <div v-if="item2.iot_id != ''">
                                    <div class="datatext" v-if="item2.status == 'standby' ">停机时间： {{ item2.last_off_time }}</div>
                                    <div class="datatext" v-if="item2.status == 'offline' ">离线时间： {{ item2.last_off_time }}</div>
                                <div class="datatext" v-if="item2.status == 'online' ">开机时间: {{ item2.last_online_time }}</div>
                                <div class="datatext" v-if="item2.status == 'standby'">已停机：{{
                                    disposeDate(item2.last_off_time) }}</div>
                                <div class="datatext" v-if="item2.status == 'online' ">已运行：{{ disposeDate(item2.last_online_time) }}</div>
                                <div class="datatext" v-if="item2.status == 'offline' "> 已离线</div>
                                <div class="datatext">今日开机总时间：{{
                                    parseInt(item2.total_online_time / 60) + '小时' + item2.total_online_time % 60 + '分钟'
                                }}</div>
                                         <div class="datatext">开机率：{{ (item2.total_online_time / item2.plan_work_time * 100 /
                                    60).toFixed(0) }}%</div>
                                <div class="jdtbox">
                                    <div class="jdt"
                                        :style="{ width: (item2.total_online_time / (item2.plan_work_time * 60) * 100).toFixed(2) + '%' }">
                                    </div>
                                </div>
                                </div>
                              


                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>

            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters} from "vuex";
import echarts from "echarts";

require("echarts/theme/macarons"); // echarts theme
import {debounce} from "@/utlis";
import {getMonth} from "@/utlis/date";
import {stoken, bind} from "@/service/api";
import {setToken} from "@/service/lib/localToken";
import Cookies from 'js-cookie';
// import allhed from "./components/allhed";

export default {
    name: "Home",

    data() {
        return {
            wsw: window.screen.width,
            boxSize: 0,  //0最大 5最小1
            Screen: false,
            isphone: false,
            count: 0,
            isall: true,
            username: Cookies.get('userNane'),
            logo: Cookies.get('logo'),
            ws: null,
            date: new Date().valueOf(),
            chart: null,
            resizeHandler: null,
            radio1: "今日",
            datevalue1: "",
            chartList: [
                {
                    list: [
                        {value: 235, name: "开机1"},
                        {value: 274, name: "停机"}
                    ]
                },
                {
                    list: [
                        {value: 50, name: "开机"},
                        {value: 80, name: "停机"}
                    ]
                },
                {
                    list: [
                        {value: 90, name: "开机"},
                        {value: 80, name: "停机"}
                    ]
                }
            ],
            list: [],
            onlinenum: "",
            offnum: "",
            ontime: 0,
            //断开链接弹窗
            isws: true,
            xslist: [],
            swlistdata: [],
            xstype: 'all'
        };
    },
    computed: {
        ...mapGetters(["sidebarOpened"]),

        Zplan_work_time: function () {
            let zong = 0;
            this.list.forEach(item => {
                zong = zong + (item.plan_work_time * item.devices.length);
            });
            return zong;
        }
    },
    watch: {
        sidebarOpened() {
            this.resizeHandler();
        }
    },

    methods: {


        enlarge() {
            if (this.boxSize > 0) {
                this.boxSize = this.boxSize - 1;
            } else {
                this.$message({
                    message: '已经是最大了',
                    type: 'warning'
                });
            }

        },
        narrow() {
            if (this.boxSize < 5) {
                this.boxSize = this.boxSize + 1;
            } else {
                this.$message({
                    message: '已经是最小了',
                    type: 'warning'
                });
            }
        },
        all() {
            //全屏
            // var docElm = document.documentElement;
            // //W3C
            // if (docElm.requestFullscreen) {
            //   docElm.requestFullscreen();
            // }

            // //FireFox
            // else if (docElm.mozRequestFullScreen) {
            //   docElm.mozRequestFullScreen();
            // }

            // //Chrome等
            // else if (docElm.webkitRequestFullScreen) {
            //   docElm.webkitRequestFullScreen();
            // }

            // //IE11
            // else if (docElm.msRequestFullscreen) {
            //   docElm.msRequestFullscreen();
            // }
            this.isall = true;
        },
        showScreen() {
            var docElm = document.documentElement;
            if (!this.Screen) {
                // 全屏操作

                //W3C
                if (docElm.requestFullscreen) {
                    docElm.requestFullscreen();
                }

                //FireFox
                else if (docElm.mozRequestFullScreen) {
                    docElm.mozRequestFullScreen();
                }

                //Chrome等
                else if (docElm.webkitRequestFullScreen) {
                    docElm.webkitRequestFullScreen();
                }

                //IE11
                else if (docElm.msRequestFullscreen) {
                    docElm.msRequestFullscreen();
                }
                this.Screen = true;
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                }
                //FireFox
                else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                }
                //Chrome等
                else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                }
                //IE11
                else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
                this.Screen = false;
            }

        },
        goto(url) {
            this.$router.push(url);
        },
        offall() {
            this.$router.push('/sc/companyOrder');
        },
        offphone() {
            this.$router.push('phoneProductionOrder');
        },
        Achart() {
            this.chartList.forEach((item, index) => {
                console.log(item);
                let chartDom = document.getElementById("Achart" + index);
                let myChart = echarts.init(chartDom);
                myChart.setOption({
                    series: [
                        {
                            name: "开机时间",
                            type: "pie",
                            radius: "80%",
                            data: item.list
                        }
                    ]
                });
            });
        },
        initChart() {
            // this.chart = echarts.init(document.getElementById('home-traffic-chart'),'macarons');

            let app = {};

            let chartDom = document.getElementById("home-traffic-chart");
            let myChart = echarts.init(chartDom);
            let option;

            let cellSize = [80, 80];
            let pieRadius = 30;

            function getVirtulData() {
                console.log(getMonth(0), getMonth(1));
                let date = +echarts.number.parseDate(getMonth(0));
                console.log(date);
                let end = +echarts.number.parseDate(getMonth(1));
                let dayTime = 3600 * 24 * 1000;
                let data = [];
                for (let time = date; time < end; time += dayTime) {
                    data.push([
                        echarts.format.formatTime("yyyy-MM-dd", time),
                        Math.floor(Math.random() * 10000)
                    ]);
                }
                return data;
            }

            function getPieSeries(scatterData, chart) {
                return scatterData.map(function (item, index) {
                    let center = chart.convertToPixel("calendar", item);
                    return {
                        id: index + "pie",
                        type: "pie",
                        center: center,
                        label: {
                            normal: {
                                formatter: "{c}",
                                position: "inside"
                            }
                        },
                        radius: pieRadius,
                        data: [
                            {name: "开机", value: Math.round(Math.random() * 24)},
                            {name: "关机", value: Math.round(Math.random() * 24)}
                        ]
                    };
                });
            }

            function getPieSeriesUpdate(scatterData, chart) {
                return scatterData.map(function (item, index) {
                    let center = chart.convertToPixel("calendar", item);
                    return {
                        id: index + "pie",
                        center: center
                    };
                });
            }

            let scatterData = getVirtulData();

            option = {
                tooltip: {},
                legend: {
                    data: ["开机", "关机"],
                    bottom: 20
                },
                calendar: {
                    top: "middle",
                    left: "center",
                    orient: "vertical",
                    cellSize: cellSize,
                    yearLabel: {
                        show: false,
                        fontSize: 30
                    },
                    dayLabel: {
                        margin: 20,
                        firstDay: 1,
                        nameMap: [
                            "星期日",
                            "星期一",
                            "星期二",
                            "星期三",
                            "星期四",
                            "星期五",
                            "星期六"
                        ]
                    },
                    monthLabel: {
                        show: false
                    },
                    range: [getMonth(2)]
                },
                series: [
                    {
                        id: "label",
                        type: "scatter",
                        coordinateSystem: "calendar",
                        symbolSize: 1,
                        label: {
                            show: true,
                            formatter: function (params) {
                                return echarts.format.formatTime("dd", params.value[0]);
                            },
                            offset: [-cellSize[0] / 2 + 10, -cellSize[1] / 2 + 10],
                            fontSize: 14
                        },
                        data: scatterData
                    }
                ]
            };

            let pieInitialized;
            setTimeout(function () {
                pieInitialized = true;
                myChart.setOption({
                    series: getPieSeries(scatterData, myChart)
                });
            }, 10);

            app.onresize = function () {
                if (pieInitialized) {
                    myChart.setOption({
                        series: getPieSeriesUpdate(scatterData, myChart)
                    });
                }
            };

            option && myChart.setOption(option);
        },
        initResizeEvent() {
            window.addEventListener("resize", this.resizeHandler);
        },
        // c
        initWebSocket() {
            let taht = this;
            if ("WebSocket" in window) {
                console.log("准备链接");
                taht.ws = new WebSocket("wss://iot.cncbkw.com/wss");
                taht.ws.onopen = function () {
                    console.log(taht.ws);
                };
                taht.ws.onerror = function () {
                    console.log("链接失败");
                };
                taht.ws.onmessage = function (e) {
                    console.log("收到服务端的消息：" + e.data);
                    console.log(JSON.parse(e.data));
                    taht.setjson(e.data);
                };
                taht.ws.onclose = function (e) {
                    console.log("断开链接" + e);

                    if (taht.isws) {
                        console.log("重新链接");
                        taht.$alert("请检查网络后，重新链接", "网络错误", {
                            confirmButtonText: "确定",
                            callback: () => {
                                location.reload();
                            }
                        });
                    }
                };
            } else {
                alert("此浏览器不支持WebSocket");
            }
        },
        // 时间处理换算
        dateConvert(value) {
            let hour,
                min,
                sec,
                createDate = value / 1000;
            hour = parseInt(createDate / 60 / 60);
            min = parseInt((createDate - hour * 3600) / 60);
            sec = parseInt(createDate - (hour * 3600 + min * 60));
            if (hour > 0) {
                if (min > 0) {
                    if (sec > 0) {
                        createDate = hour + "时" + min + "分" + sec + "秒";
                    } else {
                        createDate = hour + "时" + min + "分";
                    }
                } else {
                    if (sec > 0) {
                        createDate = hour + "时" + sec + "秒";
                    } else {
                        createDate = hour + "时";
                    }
                }
            } else if (min > 0) {
                createDate = sec > 0 ? min + "分" + sec + "秒" : min + "分";
            } else if (sec > 0) {
                createDate = sec + "秒";
            }
            return createDate;
        },
        disposeDateM(itme) {
            let newitme = '';
            var u = navigator.userAgent;
            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isiOS) {
                newitme = this.date - new Date(itme.replace(/-/g, '/')).getTime();
            } else {
                // 安卓系统
                newitme = this.date - new Date(itme).getTime();
            }
            let hour,
                min,
                sec,
                createDate = newitme / 1000;
            hour = parseInt(createDate / 60 / 60);
            min = parseInt((createDate - hour * 3600) / 60);
            sec = parseInt(createDate - (hour * 3600 + min * 60));
            let data = {
                hour,
                min,
                sec,
                createDate
            };
            return data;
        },
        disposeDate(itme) {
            let newitme = '';

            var u = navigator.userAgent;
            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isiOS) {
                newitme = this.date - new Date(itme.replace(/-/g, '/')).getTime();
            } else {
                // 安卓系统
                newitme = this.date - new Date(itme).getTime();
            }
            return this.dateConvert(newitme);
            // return itme.replace(/-/g, '/');


        },
        // json 处理
        setjson(data) {
            data = JSON.parse(data);
            if (data.type === 'init') {
                console.log('初始化');
                let sdata = {
                    client_id: data.client_id
                };
                bind(sdata).then(res => {
                    console.log(res);
                    if (res.code === 200) {
                        console.log(this.ws);
                        this.ws.send('{"mode":"group", "company_id": "11"}');
                        console.log("链接成功");
                    }

                });
                return;
            }
            if (data.code === 200) {
                console.log("初始消息");
                console.log(data);
                let list = data.data.group;
                console.log(typeof list);
                let zong = 0;
                list.forEach(item => {
                    zong = zong + item.devices.length;
                    item.device_count = 0;
                    item.devices.forEach(item2 => {
                        if (item2.status === "online") {
                            item2.xdate = this.disposeDateM(item2.last_online_time).min;
                        } else {
                            item2.xdate = 0;
                        }
                        if (item2.last_online_time) {
                            item2.last_online_time = item2.last_online_time.substring(0, 19);
                        }
                        if (item2.last_off_time) {
                            item2.last_off_time = item2.last_off_time.substring(0, 19);
                        }
                        item.device_count++;
                    });

                });
                this.count = zong;
                this.list = list;
                console.log(this.list);
                this.swlist(list);
            }
            if (data.msg === "ok") {
                console.log(data);

                let list = this.list;
                this.list.forEach((item, index) => {
                    item.offline_count = data.data.group[index].offline_count;
                 
                    item.zongnum = item.devices.length;
                    console.log(item);
                    item.online_count = data.data.group[index].online_count;
                    item.device_count = 0;
                    item.devices.forEach(item2 => {
                        if (item2.iot_id === data.data.iot_id) {
                            item2.xdate = 0;
                            item2.status = data.data.status;
                            item2.last_off_time = data.data.last_off_time.substring(0, 19);
                            item2.last_online_time = data.data.last_online_time.substring(0, 19);
                            item2.total_online_time = data.data.total_online_time;
                            item2.total_off_time = data.data.total_off_time;
                            item2.staff_name = data.data.staff_name;
                            item2.curr_material_name = data.data.curr_material_name;
                            item2.production_start_time = data.data.production_start_time;
                            console.log(item2);
                            item.device_count++;
                        }
                    });
                    console.log(item.device_count);
                });
                console.log(list);
                this.list = list;
                this.swlist(list);
            }
            this.isoff();
        },
        tan(type) {
            this.xstype = type;
            this.swlist(this.list);
        },
        swlist(list) {

            console.log(list);
            let zonglist = [];
            if (this.xstype === 'all') {

                list.forEach(item => {
                    item.devices.forEach(item2 => {
                        item2.plan_work_time = item.plan_work_time;
                        item2.group_name = item.group_name;
                        if (item2.status === 'offline') {
                            zonglist.push(item2);
                        } else {
                            zonglist.unshift(item2);
                        }
                    });
                });
            } else {
                list.forEach(item => {
                    item.devices.forEach(item2 => {
                        item2.plan_work_time = item.plan_work_time;
                        item2.group_name = item.group_name;
                        if (item2.group_name === this.xstype) {
                            zonglist.push(item2);
                        }
                    });
                });
            }
            // 不分部门 整合一下大数组  并且按开机状态排序


            // 按15 一页分页
            // let fenlist = [];
            this.swlistdata = this.getNewArray(zonglist, 12);
            console.log(this.swlistdata);
        },
        getNewArray(array, subGroupLength) {
            // array 代表一个数组
            // subGroupLength 代表数组里面的数组的length值
            let index = 0;
            let newArray = [];
            while (index < array.length) {
                newArray.push(array.slice(index, (index += subGroupLength)));
            }
            return newArray;

        },
        // 判断几个上线
        isoff() {
            let off = 0;
            let onlin = 0;
            let ontime = 0;
            this.list.forEach(item => {
                item.devices.forEach(item2 => {
                    if (item2.status === "offline") {
                        off++;
                    } else {
                        onlin++;
                    }
                    // 计算开机时间
                    ontime = ontime + item2.total_online_time;
                });

            });
            this.onlinenum = onlin;
            this.offnum = off;
            this.ontime = ontime;
            // this.ontime = parseInt(ontime / 60) + "小时" + (ontime % 60) + "分钟";
        },
        device(id) {
            if (id) {
                this.$router.push("/one?id=" + id);
            }
        }
    },
    created() {
        stoken().then(res => {
            const {token} = res.data;
            setToken(token);
            this.initWebSocket();
        });


    },
    destroyed() {
        this.isws = false;
        this.ws.close(); //离开路由之后断开websocket连接
    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
        }
    },
    mounted() {
        const _this = this;
        _this.resizeHandler = debounce(() => {
            if (_this.chart) {
                _this.chart.resize();
            }
        }, 100);

        _this.initResizeEvent();
        // this.initChart();
        // this.Achart();
        this.timer = setInterval(() => {
            _this.date = new Date(); // 修改数据date
        }, 1000);
        this.all();

    }
};
</script>
<style>
.el-carousel__indicator.is-active button {
    opacity: 1;
    background-color: #53FFFF !important;
}

body,
html {
    height: 100%;
}

#app {
    height: 100%;
}

body {
    background: #04274D;
}
</style>
<style lang="scss" scoped>
.v-modal {
    z-index: 1 !important;
}

.statistmain {

    // zoom: 80%;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    background: #04274D;
    position: relative;

    .stathed {
        width: 100%;
        height: 120px;
        position: relative;

        .bg {
            width: 100%;
            height: 200px;
        }

        .textbox {
            position: absolute;
            width: 100%;
            text-align: center;
            top: 0;
            left: 0;
            height: 90px;

            p {
                margin: 0;

                &:nth-child(1) {
                    color: #FFFFFF;
                    font-weight: 600;
                    font-size: 40px;
                    line-height: 70px;
                }

                &:nth-child(2) {
                    color: #FFFFFF;
                    line-height: 14px;
                    font-size: 14px;
                }
            }
        }

        .tabbox {
            position: absolute;
            top: 44px;
            left: 40px;

            .tab {
                width: 105px;
                height: 40px;
                background: linear-gradient(180deg, rgba(8, 139, 255, 0.4) 0%, rgba(8, 139, 255, 0) 100%);
                box-shadow: inset 0px 0px 5px 4px rgba(8, 139, 255, 0.6);
                border-radius: 4px 4px 4px 4px;
                opacity: 1;
                text-align: center;
                line-height: 40px;
                color: #ACD8FF;
                font-size: 16px;
                float: left;
                margin-right: 32px;
                cursor: pointer;
            }

            .avtive {
                color: #FFFFFF;
                background: linear-gradient(180deg, #088BFF 0%, rgba(8, 139, 255, 0) 100%);
            }
        }

        .sy {
            cursor: pointer;
            width: 120px;
            height: 40px;
            background: linear-gradient(180deg, rgba(8, 139, 255, 0.4) 0%, rgba(8, 139, 255, 0) 100%);
            box-shadow: inset 0px 0px 5px 4px rgba(8, 139, 255, 0.6);
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            text-align: center;
            line-height: 40px;
            color: #ACD8FF;
            font-size: 16px;

            margin-right: 32px;
            position: absolute;
            top: 44px;
            right: 140px;
        }

        .sjbox {
            position: absolute;
            top: 44px;
            right: 40px;

            p {
                margin: 0;

                &:nth-child(1) {
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 28px;
                    color: #EFF7FF
                }

                &:nth-child(2) {
                    font-size: 18px;
                    color: #EFF7FF;
                    line-height: 28px;
                }
            }
        }
    }

    .stebox {
        width: calc(100% - 80px);
        margin: 0 auto;
        height: 920px;
        background: rgba(12, 109, 212, 0.2);
        border-radius: 1px 1px 1px 1px;
        opacity: 1;
        border: 1px solid;

        border-image: linear-gradient(225deg, rgba(51, 204, 204, 0), rgba(51, 204, 204, 0.2), rgba(51, 204, 204, 0)) 1 1;


        .steboxhed {
            width: 513px;
            height: 48px;
            background-image: url(../../img/shed.png);
            font-weight: 600;
            font-size: 18px;
            line-height: 48px;
            color: #ACD8FF;
            padding-left: 55px;
            box-sizing: border-box;
        }

        .settabbox {
            width: 100%;
            height: 48px;
            line-height: 48px;
            overflow: hidden;
            padding: 0 40px;
            box-sizing: border-box;

            .settab {
                font-size: 18px;
                font-weight: 400;
                color: #ACD8FF;
                float: left;
                margin-right: 40px;
                position: relative;
                cursor: pointer;

                .xian {
                    content: '';
                    width: 32px;
                    height: 4px;
                    background: #53FFFF;
                    border-radius: 12px 12px 12px 12px;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 0px;
                    display: none;

                }
            }


            .active {
                color: #53FFFF;

                .xian {
                    display: block;
                }

            }
        }

        .setmain {
            height: calc(100% - 48px);
        }

    }

    .ddboxsw {
        width: 100%;
        height: calc(100% - 48px);
        // height: 100%;
    }

    .ddbox {
        width: 100%;
        overflow-y: scroll;
        height: calc(100% + 18px);
        // height: 100%;
        padding-bottom: 20px;
        box-sizing: border-box;


    }

    .jq {
        width: 286px;
        height: 350px;
        background: #09437B;
        border-radius: 4px 4px 4px 4px;
        margin-left: 16px;
        margin-top: 16px;
        padding: 16px;
        box-sizing: border-box;
        position: relative;
        float: left;

        img {
            width: 60px;
            height: 62px;
            position: absolute;
            right: 16px;
            top: 16px;
        }

        .name {
            font-size: 18px;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 36px;
        }

        .datatext {
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 32px;
        }

        .jdtbox {
            width: 100%;
            height: 12px;
            background: #1E78CE;
            border-radius: 23px 23px 23px 23px;
            position: relative;
            margin-top: 10px;

            .jdt {
                position: absolute;
                height: 12px;
                top: 0;
                left: 0;
                background: #53FFFF;
                border-radius: 23px 23px 23px 23px;

            }
        }
    }
}</style>
